import { lazy } from 'react';
const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../home/Home')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/home',
    component: lazy(() => import('../home/Home')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/department',
    component: lazy(() => import('../department/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/position',
    component: lazy(() => import('../position/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/teammember',
    component: lazy(() => import('../teammember/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/brand',
    component: lazy(() => import('../brand/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/work',
    component: lazy(() => import('../work/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/grenton',
    component: lazy(() => import('../grenton/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/blog',
    component: lazy(() => import('../blog/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user',
    component: lazy(() => import('../user/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/seo',
    component: lazy(() => import('../meta/SEO')),
    exact: true,
  },
];
export default options;
